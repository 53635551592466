<template>
  <div class="container">
    <user-nav/>

    <div class="page">
      <div class="page__title">Информация об оплате</div>

      <div class="content">
        <div class="section">
          <div class="section__title">Порядок формирования цены за услуги сервиса Provereno.Online</div>
          <div>
            Стоимость услуги Заказчик определяет самостоятельно, в зависимости от предложенных на сайте вариантов
            электронного отчета по проверке объекта недвижимости. Если пользователь не зарегистрирован на сайте,
            просмотр содержимого на ресурсе – анонимный.
          </div>
        </div>
        <div class="section">
          <div class="section__title">Способ оплаты</div>
          <div>
            Прием платежей предоставлен АО «Тинькофф Банк».<br>
            Сейчас для оплаты заказа вы можете воспользоваться одним из следующих способов оплаты:
            <ul>
              <li>– банковские карты: Visa, MasterCard или МИР;</li>
              <li>– платёжные системы: Tinkoff Pay или Yandex Pay;</li>
              <li>– система быстрых платежей СБП.</li>
            </ul>
            В соответствии с ФЗ «О защите прав потребителей» в случае, если вам была оказана услуга
            или реализован товар ненадлежащего качества, платеж может быть возвращен на банковскую карту
            с которой производилась оплата.
          </div>
        </div>
        <div class="section">
          <div class="section__title">Данные получателя</div>
          <div>
            "ПРАЙМ-СЕРВИС НЕДВИЖИМОСТЬ"<br>
            ИП Потапов Андрей Олегович<br>
            ИНН: 771887951901<br>
            ОРГНИП: 323774600236946<br>
            Юридический адрес: 125183, город Москва, ул. Генерала Рычагова, д.16, кв.67<br>
            Фактический адрес: 105064, г. Москва, ул. Земляной Вал, д. 34А, строение 2
          </div>
        </div>
      </div>

      <div class="card">
        <div class="files">
          <a :href="require(`@/assets/pdf/oferta.pdf`).default" target="_blank" class="file">Договор оферты</a>
          <a :href="require(`@/assets/pdf/privacy_policy.pdf`).default" target="_blank" class="file">Политика конфиденциальности</a>
          <a :href="require(`@/assets/pdf/vozvrat.pdf`).default" target="_blank" class="file">Условия возврата товара</a>
          <a :href="require(`@/assets/pdf/otkaz.pdf`).default" target="_blank" class="file">Условия отказа от услуги</a>
        </div>
        <img class="bank-logo" src="@/assets/img/tinkoff.png">
        <div class="payment-logos">
          <img class="payment-logo" src="@/assets/img/visa.png" alt="Visa">
          <img class="payment-logo" src="@/assets/img/mastercard.png" alt="Mastercard">
          <img class="payment-logo" src="@/assets/img/mir.png" alt="МИР">
        </div>
        <span>
          Интернет-платежи защищены сертификатом SSL и протоколом 3D Secure. АО «Тинькофф Банк» не передает магазинам платежные данные, в том числе данные карты. Сервис предоставлен АО «Тинькофф Банк».
        </span>
      </div>

    </div>
  </div>
</template>

<script>

import UserNav from '@/components/UserNav'
export default {
  name: 'PayInfo',
  components: {UserNav}
}
</script>

<style lang="scss">
  .page {
    display: grid;
    padding: 20px 0;

    @include lg {
      grid-template-columns: 1.5fr .5fr;
      column-gap: 30px;
    }
  }

  .page__title {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    @include lg {
      grid-column: 1 / 3;
    }
  }

  .content {
    @include lg {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }

  .section {
    text-align: left;
    padding: 10px 0;
    line-height: 1.35;

    @include sm {
      font-size: 18px;
    }

    @include md {
      font-size: rem-md(18px);
    }

    @include lg {
      font-size: rem-lg(20px);
    }
  }

  .section__title {
    font-weight: 500;
    padding-bottom: 5px;
  }

  .card {
    display: grid;
    grid-auto-flow: row;
    font-size: 10px;
    text-align: left;
    color: #8188a1;

    @include lg {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      padding: 10px;
      border-radius: 3px;
      background-color: white;
    }
  }

  .file {
    display: flex;
    text-align: left;
    color: black;
    text-decoration: none;
    font-weight: 500;
    padding: 5px 0;
    align-items: center;

    &::before {
      content: '';
      display: block;
      background: url("/icons/pdf.svg") 0 0 no-repeat;
      background-size: contain;
      height: 30px;
      width: 24px;
      padding-right: 5px;
    }
  }

  .bank-logo {
    max-height: 70px;
  }

  .payment-logos {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5px
  }

  .payment-logo {
    display: block;
    flex: 1 0;
    max-width: 60px;
  }
</style>
